import React from "react";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import "./global.css";
import "antd/dist/antd.css";
import Home from "./pages/Home";

const App: React.FC = () => (
  <Router>
    <Switch>
      <Route path="/">
        <Home />
      </Route>
    </Switch>
  </Router>
);

export default App;
