import React, { useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import {
  Table,
  message,
  DatePicker,
  TreeSelect,
  TreeDataNode,
  Button,
} from "antd";
import { groupBy, find } from "lodash";
import moment, { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import { ColumnProps } from "antd/lib/table";
import XLSX from "xlsx";

import styles from "./index.module.css";
import { getAnimalVisitsByLocation, getLocations } from "../../service/api";

const { RangePicker } = DatePicker;

interface AnimalVisitSummary {
  uuid: string;
  responder: string;
  location: string;
  totalFeedIntake: number;
  weightDelta: number;
  feedWeightRatio: number;
}

interface LocationsMap {
  [uuid: string]: string;
}

interface ByDay {
  [date: string]: number;
}

interface MyTreeDataNode extends TreeDataNode {
  id: number | null;
}

const MAX_PERIOD = 30;

const displayErrorMessages = (err: any) => {
  if (Array.isArray(err.response?.data.messages)) {
    err.response.data.messages.forEach((item: string) => {
      message.error(item);
    });
  }
};

const requireAuth = () => {
  window.location.replace("https://api.nedap-pigdata.cn/auth");
};

const generateDatesArray = (startDate: Moment, endDate: Moment) => {
  const dates = [];
  const currentDate = moment(startDate);
  while (currentDate.isBefore(endDate)) {
    dates.push(currentDate.format("MMM-D"));
    currentDate.add(1, "d");
  }
  return dates;
};

const openDownloadDialog = (resource: object | string, name: string) => {
  const a = document.createElement("a");
  a.href =
    typeof resource === "object" && resource instanceof Blob
      ? URL.createObjectURL(resource)
      : String(resource);
  a.download = name || "";
  const event = new MouseEvent("click");
  a.dispatchEvent(event);
};

const median = (arr: number[]) => {
  const mid = Math.floor(arr.length / 2);
  const sorted = arr.sort((a, b) => a - b);
  return arr.length % 2 !== 0
    ? sorted[mid]
    : (sorted[mid - 1] + sorted[mid]) / 2;
};

const Home: React.FC = () => {
  const [accessToken, setAccessToken] = useState<string | null>();
  const [allLocations, setAllLocations] = useState<Location[]>();
  const [selectedLocations, setSelectedLocations] = useState<string[]>();
  const [animalVisitSummaryList, setAnimalVisitSummaryList] = useState<
    AnimalVisitSummary[]
  >([]);
  const [selectedDates, setSelectedDates] = useState<RangeValue<Moment>>();
  const [hackDatePickerValue, setHackDatePickerValue] =
    useState<RangeValue<Moment>>();
  const [datePickerValue, setDatePickerValue] = useState<RangeValue<Moment>>();
  const [tempDates, setTempDates] = useState<RangeValue<Moment>>();
  const [loading, setLoading] = useState(false);
  const [feedColumns, setFeedColumns] = useState<
    ColumnProps<AnimalVisitSummary>[]
  >([]);
  const [weightColumns, setWeightColumns] = useState<
    ColumnProps<AnimalVisitSummary>[]
  >([]);

  const query = new URLSearchParams(useLocation().search);
  const queryAccessToken = query.get("accessToken");

  const locationsMap = useMemo(() => {
    const tmp: LocationsMap = {};
    if (Array.isArray(allLocations)) {
      allLocations.forEach((item) => {
        tmp[item.uuid] = item.name || item.uuid;
      });
    }
    return tmp;
  }, [allLocations]);

  const locationsTreeData = useMemo(() => {
    const tempTreeData: MyTreeDataNode[] = [];
    allLocations?.forEach((item) => {
      const node = {
        title: item.name,
        id: item.id,
        value: item.uuid,
        key: item.uuid,
      };
      if (item.parent_id) {
        const parent = find(tempTreeData, { id: item.parent_id });
        if (parent) {
          if (Object.prototype.hasOwnProperty.call(parent, "children")) {
            parent.children?.push(node);
          } else {
            parent.children = [node];
          }
        }
      } else {
        tempTreeData.push(node);
      }
    });
    return tempTreeData;
  }, [allLocations]);

  useEffect(() => {
    if (queryAccessToken) {
      setAccessToken(queryAccessToken);
    } else {
      requireAuth();
    }
  }, [queryAccessToken]);

  useEffect(() => {
    if (accessToken) {
      getLocations({ token: accessToken })
        .then((res) => {
          setAllLocations(res.data.locations);
        })
        .catch((err) => {
          if (err.response) {
            displayErrorMessages(err);
            if (err.response.status === 401) {
              requireAuth();
            }
          }
        });
    }
  }, [accessToken]);

  useEffect(() => {
    if (tempDates && tempDates[0] && tempDates[1]) {
      setSelectedDates(tempDates);
    }
  }, [tempDates]);

  useEffect(() => {
    const datesArray =
      selectedDates && selectedDates[0] && selectedDates[1]
        ? generateDatesArray(selectedDates[0], selectedDates[1])
        : generateDatesArray(moment().subtract(10, "days"), moment());
    const newFeedColumns = datesArray.map((date) => ({
      title: date,
      dataIndex: `feed_intake_${date}`,
    }));
    const newWeightColumns = datesArray.map((date) => ({
      title: date,
      dataIndex: `animal_weight_${date}`,
    }));
    setFeedColumns(newFeedColumns);
    setWeightColumns(newWeightColumns);
  }, [selectedDates]);

  const handleLocationSelectChange = (value: any) => {
    setSelectedLocations(value);
  };

  const handleDataExport = () => {
    const sheet = XLSX.utils.json_to_sheet(
      animalVisitSummaryList.map((item) => ({
        ...item,
        responder: `'${item.responder}`,
      }))
    );
    const csv = XLSX.utils.sheet_to_csv(sheet);
    const blob = new Blob([`\ufeff${csv}`], { type: "text/csv,charset=UTF-8" });
    openDownloadDialog(
      blob,
      `farm_data_export_${moment().format("YMDhhmmssSSS")}`
    );
  };

  const handleNewQuery = () => {
    if (accessToken && Array.isArray(selectedLocations)) {
      setLoading(true);
      const tasks = [];
      for (const location of selectedLocations) {
        tasks.push(
          getAnimalVisitsByLocation({
            token: accessToken,
            locationId: location,
            startDate: selectedDates
              ? selectedDates[0]
                ? selectedDates[0]
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .toISOString()
                : ""
              : "",
            endDate: selectedDates
              ? selectedDates[1]
                ? selectedDates[1]
                    .hour(23)
                    .minute(59)
                    .second(59)
                    .millisecond(999)
                    .toISOString()
                : ""
              : "",
          })
        );
      }
      Promise.all(tasks)
        .then((values) => {
          const summarizedAnimalVisits: AnimalVisitSummary[] = [];
          for (const { status, data } of values) {
            const { animal_visits } = data;
            if (status === 200 && Array.isArray(animal_visits)) {
              const animalVisitsByAnimalId = groupBy(
                animal_visits,
                "animal_uuid"
              );
              for (const key of Object.keys(animalVisitsByAnimalId)) {
                const currentAnimalVisits = animalVisitsByAnimalId[key];
                const tmpList = currentAnimalVisits.map((item) => ({
                  feed_intake: item.feed_intake,
                  animal_weight: item.animal_weight,
                  date: moment(item.start_time).format("MMM-D"),
                }));
                const currentAnimalVisitsByDay = groupBy(tmpList, "date");
                const dates = Object.keys(currentAnimalVisitsByDay);
                const feedIntakeByDay: ByDay = {};
                const weightByDay: ByDay = {};
                for (const date of dates) {
                  const currentAnimalVisitsOfCurrentDay =
                    currentAnimalVisitsByDay[date];
                  feedIntakeByDay[`feed_intake_${date}`] =
                    currentAnimalVisitsOfCurrentDay.reduce(
                      (a, b) => a + b.feed_intake,
                      0
                    );
                  weightByDay[`animal_weight_${date}`] = median(
                    currentAnimalVisitsByDay[date].map(
                      (item) => item.animal_weight
                    )
                  );
                }
                const totalFeedIntake = Object.keys(feedIntakeByDay).reduce(
                  (a, b) => a + feedIntakeByDay[b],
                  0
                );
                const weightDelta =
                  weightByDay[
                    Object.keys(weightByDay)[
                      Object.keys(weightByDay).length - 1
                    ]
                  ] - weightByDay[Object.keys(weightByDay)[0]];
                const feedWeightRatio = totalFeedIntake / weightDelta;
                summarizedAnimalVisits.push({
                  uuid: key,
                  responder: currentAnimalVisits[0].responder,
                  location: locationsMap[currentAnimalVisits[0].location_uuid],
                  ...feedIntakeByDay,
                  ...weightByDay,
                  totalFeedIntake,
                  weightDelta,
                  feedWeightRatio,
                });
              }
            }
          }
          setAnimalVisitSummaryList(summarizedAnimalVisits);
        })
        .catch(displayErrorMessages)
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      setTempDates(null);
    }
    setHackDatePickerValue(null);
  };

  const disabledDate = (current: moment.Moment) => {
    if (current > moment()) {
      return true;
    }
    if (!tempDates || tempDates.length < 1) {
      return false;
    }
    const tooLate =
      !!tempDates[0] && current.diff(tempDates[0], "days") > MAX_PERIOD;
    const tooEarly =
      !!tempDates[1] && tempDates[1].diff(current, "days") > MAX_PERIOD;
    return tooEarly || tooLate;
  };

  const columns1 = [
    {
      title: "Responder",
      dataIndex: "responder",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Feed Intake / Day",
      children: feedColumns,
    },
    {
      title: "Total Feed Intake",
      dataIndex: "totalFeedIntake",
    },
  ];

  const columns2 = [
    {
      title: "Responder",
      dataIndex: "responder",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Weight / Day",
      children: weightColumns,
    },
    {
      title: "Weight Delta",
      dataIndex: "weightDelta",
    },
  ];

  const columns3: ColumnProps<AnimalVisitSummary>[] = [
    {
      title: "Responder",
      dataIndex: "responder",
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Total Feed Intake",
      dataIndex: "totalFeedIntake",
    },
    {
      title: "Weight Delta",
      dataIndex: "weightDelta",
    },
    {
      title: "FCR",
      key: "feedWeightRatio",
      render: (text, record) => <>{record.feedWeightRatio.toFixed(2)}</>,
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        <RangePicker
          className="flex-shrink-0"
          value={hackDatePickerValue || datePickerValue}
          disabledDate={disabledDate}
          onCalendarChange={(val) => setTempDates(val)}
          onChange={(val) => setDatePickerValue(val)}
          onOpenChange={onOpenChange}
          disabled={loading}
        />
        <TreeSelect
          className="flex-grow-1"
          treeData={locationsTreeData}
          placeholder="Select locations"
          treeDefaultExpandAll
          allowClear
          multiple
          treeCheckable={true}
          onChange={handleLocationSelectChange}
          disabled={loading}
        />
        <Button onClick={handleNewQuery} disabled={loading}>
          查询
        </Button>
        <Button onClick={handleDataExport} disabled={loading}>
          导出 CSV
        </Button>
      </div>
      <div className="flex justify-content-center">
        <div className={styles.tableContainer}>
          <Table
            rowKey="uuid"
            dataSource={animalVisitSummaryList}
            columns={columns1}
            loading={loading}
            scroll={{ x: true }}
          />
        </div>
      </div>
      <div className="flex justify-content-center">
        <div className={styles.tableContainer}>
          <Table
            rowKey="uuid"
            dataSource={animalVisitSummaryList}
            columns={columns2}
            loading={loading}
            scroll={{ x: true }}
          />
        </div>
      </div>
      <div className="flex justify-content-center">
        <div className={styles.tableContainer}>
          <Table
            rowKey="uuid"
            dataSource={animalVisitSummaryList}
            columns={columns3}
            loading={loading}
            scroll={{ x: true }}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
