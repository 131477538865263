import { request } from "./request";

export const getAnimals = (options: { token: string | null }) => {
  return request.get<API.GetAnimalsResponse>("/animals", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });
};

export const getAnimalVisits = (options: {
  token: string | null;
  animalId: string;
  startDate?: string;
  endDate?: string;
}) => {
  return request.get<API.GetAnimalVisitsResponse>(
    "/pig_performance_testing/animal/animal_visits",
    {
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
      params: {
        animal_uuid: options.animalId,
        start_date: options.startDate,
        end_date: options.endDate,
      },
    }
  );
};

export const getLocations = (options: { token: string | null }) => {
  return request.get<API.GetLocationsResponse>("/locations", {
    headers: {
      Authorization: `Bearer ${options.token}`,
    },
  });
};

export const getAnimalVisitsByLocation = (options: {
  token: string | null;
  locationId: string;
  startDate?: string;
  endDate?: string;
}) => {
  return request.get<API.GetAnimalVisitsByLocationResponse>(
    "/pig_performance_testing/location/animal_visits",
    {
      headers: {
        Authorization: `Bearer ${options.token}`,
      },
      params: {
        location_uuid: options.locationId,
        start_date: options.startDate,
        end_date: options.endDate,
      },
    }
  );
};
